import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs"
// ...GatsbyImageSharpFluid

const Skills = () => {
  return <div><h2>SKILLS</h2>
  <p>En esta sección planeo listar los cursos que he realizado y un resumen de las habilidades adquiridas.</p>
  </div> 
}

export default Skills
